.title-privacy{
    font-weight: 600;
    font-size: 350%;
}

@media(max-width: 550px){
    .title-privacy{
        font-weight: 600;
        font-size: 150%;
    }
}