@import "../../../assets/scss/colors.scss";

.menu {
  height: 160px;
  margin-right: 25px;
  margin-left: 25px;
  background-color: $salt-white;
}

.mini-menu {
  height: 40px;
  margin-right: auto;
  margin-left: auto;
  background-color: $salt-white;
}

.menu-underline {
  border-bottom: 5px solid $blue;
}

.category-title{
  color: $border-gray;
}

.carta{
  min-height: 90vh;
}

.carousel-menu-order {
  overflow-x: scroll;
  top: 145px;
  z-index: 2;
  width: 98.5%;
  border-bottom: 1px solid $border-gray;
}

.carousel-menu {
  overflow-x: scroll;
  top: 85px;
  z-index: 2;
  width: 98.5%;
  border-bottom: 1px solid $border-gray;
}

// Scrollbar menu

/* width */
.carousel-menu::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.carousel-menu::-webkit-scrollbar-track {
  background: $salt-white;
}

/* Handle */
.carousel-menu::-webkit-scrollbar-thumb {
  background: $butter-yellow;
  border-radius: 50px;
}


.card-height{
  height: 210px;
}

.allergens-border{
  border-bottom: 1px solid $blue;
}

.image-menu {
  width: 150px;
  height: auto;
  max-height: 110px;
}
.image-cover{
  object-fit: cover;
  width: auto;
  height: auto;
}
.card-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.card-name {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text-width {
  width: 55%;
}

.image-width {
  width: 45%;
  object-fit: cover;
}

.button-width {
  width: 36px;
  bottom: 0;
}

@media (max-width: 1400px){
  .carousel-menu {
    top: 85px;
  }
  .menu {
      height: 150px;
      margin-right: 15px;
      margin-left: 15px;
    }
    
    .image-menu {
      width: 140px;
      max-height: 100px;
    }

    .carousel-menu-order {
      top: 145px;
    }
    
}

@media (max-width: 990px){
  .carousel-menu {
    top: 135px;
  }
  .carousel-menu-order {
    top: 196px;
  }

}


@media (max-width: 450px){
  .menu {
      height: 110px;
      margin-right: 8px;
      margin-left: 8px;
    }

    .text-size-menu{
      font-size: 13px;
    }
    .text-size-mini-menu{
      font-size: 16px;
    }
    
    .image-menu {
      width: 100px;
      max-height: 70px;
    }
    .carousel-menu {
      top: 122px;
    }
    .carousel-menu-order {
      top: 183px;
    }
}

// Loading animation

.dot-elastic {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $blue;
  color: $blue;
  animation: dotElastic 1s infinite linear;
}

.dot-elastic::before, .dot-elastic::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-elastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $blue;
  color: $blue;
  animation: dotElasticBefore 1s infinite linear;
}

.dot-elastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $blue;
  color: $blue;
  animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}