@import "../../assets/scss/colors.scss";

.love-card{
    background-color: $calidad-color;
}

.sos-font-size{
    font-size: 53px;
}

.residuos{
    flex-direction: row;
}

.envases{
    flex-direction: row;
}

.restaurantes{
    flex-direction: row-reverse;
}

@media (max-width: 1000px){
    .residuos{
        flex-direction: column;
    }
    .envases{
        flex-direction: column;
    }
    .restaurantes{
        flex-direction: column;
    }

}