.title-register{
    font-weight: 600;
    font-size: 350%;
}

.register-button{
    width: 50%;
}

.register-success-font{
    font-size: 20px;
}
.register-success-button{
    width: 250px;
    margin-left: 15px;
    margin-right: 15px;
}
.success-register{
    height: 90vh;
}

.register-info-card{
    width: 50%;
}

@media (max-width: 800px){
    .register-info-card{
        width: 100%;
    }
}

@media (max-width: 600px){
    .title-register{
        font-weight: 600;
        font-size: 200%;
    }
    .register-success-buttons-group{
        flex-direction: column;
    }
    .register-success-button{
        width: 75%;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media (max-width: 450px){
    .register-button{
        width: 90%;
    }
}