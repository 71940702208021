.close-size{
    width: 30px;
    height: 30px;
}

.personal-data{
    min-height: 90vh;
}

.top{
    margin-top: -50px;
}

.top-buttons{
    margin-top: -40px;
}