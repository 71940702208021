@import "../../assets/scss/colors.scss";

.restaurants{
    flex-direction: row;
}

.font-size-buscar{
    font-size: 20px;
    margin-bottom: -15px;
}

.image-size{
    width: 40px;
    height: 40px;
}

.image-size-filters{
    width: 40px;
    height: 40px;
    margin-top: -10px;
}


.icons-row{
    width: 70%;
}

.checkbox-align{
    margin-left: -20px;
}

// Scrollbar restaurants

.restaurants-overflow::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .restaurants-overflow::-webkit-scrollbar-track {
    background: $salt-white;
  }
  
  /* Handle */
  .restaurants-overflow::-webkit-scrollbar-thumb {
    background: $butter-yellow;;
    border-radius: 50px;
  }

@media (max-height: 1400px){
    .restaurants-overflow{
        overflow-y: scroll;
        height: 700px;
    }
}

@media (max-height: 1000px){
    .restaurants-overflow{
        overflow-y: scroll;
        height: 600px;
    }
    
}

@media (max-height: 850px){
    .restaurants-overflow{
        overflow-y: scroll;
        height: 500px;
    }
}

@media (max-height: 600px){
    .restaurants-overflow{
        overflow-y: scroll;
        height: 300px;
    }
}

@media (max-width: 990px){
    .restaurants{
        flex-direction: column;
        justify-content: center;
    }
    .icons-row{
        width: 70%;
    }
}

@media (max-width: 600px){
    .font-size-buscar{
        font-size: 17px;
    }
    .restaurant-icons{
        flex-direction: column;
    }
    .image-size{
        width: 37px;
        height: 37px;
    }
    .image-size-filters{
        width: 37px;
        height: 37px;
        margin-top: -7px;
    }
    .restaurant-button{
        width: 200px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .horario{
        margin-top: 10px;
    }
    .icons-row{
        width: 100%;
    }
}