
@import "../../assets/scss/colors.scss";


.navbar{
    border: 1px solid $border-gray;
    background-color: $salt-white;
    min-height: 88px;
}

.chicken-font-style{
    font-family: "Chicken";
    font-size: 18px;
    font-weight: 700;
}

.lng_underline{
    border-bottom: 1px solid $blue;
}

.hacer-pedido{
    width: 100%;
}

.hacer-pedido-button{
    width: 90%;
}

.navbar-nav{
    align-items: center;
}

.font_size{
    font-size: 18px;
    font-weight: 800;
}

.pop_nav_link_language{
    font-weight: 500;
    font-size: 13px;
}

.navbar-brand{
    background: url(../../assets/img/logo/POP-LOGO.png) no-repeat;
    position: relative;
    background-size: contain;
    width: 200px;
    height: 55px;
}

#dropdown::after{
    display: none; 
 }

 .dropdown-menu {
    display: block;
    position: absolute;
    width: 250px;
    height: auto;
    right: 0;
    left: auto;
    overflow-y: scroll;
}
// Scrollbar profile dropdown

/* width */
.dropdown-menu::-webkit-scrollbar {
    height: 5px;
  }
  
  /* Track */
  .dropdown-menu::-webkit-scrollbar-track {
    background: $salt-white;
  }
  
  /* Handle */
  .dropdown-menu::-webkit-scrollbar-thumb {
    background: $butter-yellow;
    border-radius: 50px;
  }

.dropdown-text-size{
    font-size: 14px;
    font-weight: 700;
}

.chicken {
    height: 22px;
    width: 19px;
}

.pop_nav_link {
    font-family: "SansBold";
    font-size: 18px;
    color: $black;
}
.pop_nav_link:hover,
.pop_nav_link:focus{
    color: $blue;
}
.pop_nav_link.active,
.pop_nav_link:active{
    color: $blue;
}

.translation{
    width: 115px;
    margin-top: -70px;
    margin-left: -120px;
    margin-right: -1%;
}

.user-size{
    width: 42px;
    height: 42px;
}

.dropdown-size{
    width: 50px;
    height: 48px;
}

@media (min-width: 1400px){
    .translation{
        width: 120px;
        margin-top: -70px;
        margin-left: -120px;
        margin-right: -0.5%;
    }
    
}


@media (max-height: 650px){
    .dropdown-menu {
        height: 450px;
    }
}

@media (max-width: 1150px){
    .pop_nav_link {
        font-size: 16px;
    }
    .font_size{
        font-size: 16px;
    }
    .chicken {
        height: 20px;
        width: 17px;
    }
    .chicken-font-style{
        font-size: 16px;
    }
}

@media (max-width: 1090px){
    .pop_nav_link {
        font-size: 14px;
    }
    .font_size{
        font-size: 14px;
    }
    .chicken-font-style{
        font-size: 14px;
    }
}

@media (max-width: 991px){
    .navbar-nav{
        align-items: flex-start;
    }
    .font_size{
        font-size: 15px;
    }
        .nav-item{
        margin-left: 2%;
        margin-top: 20px;
    }
}


  @media (max-width: 450px){
    .navbar-brand{
        width: 150px;
        height: 40px;
    }
    .navbar{
        min-height: 70px;
    }
  }

  @media (max-width: 390px){
    .navbar-brand{
        width: 135px;
        height: 40px;
    }
    .font_size{
        font-size: 15px;
    }
   
  }
  @media (max-width: 330px){
    .font_size{
        font-size: 11px;
    }
   
  }

  
