
@import "../../assets/scss/colors.scss";

.home{
    background-color: $dirty-white;
}

.principal_banner{
    width: 100%;
    height: auto;
}

.separate{
    width: 100%;
    height: 50px;
}

.border-rounded{
    -webkit-border-radius: 20px;
	-khtml-border-radius: 20px;	
	-moz-border-radius: 20px;
	border-radius: 20px;
}

.close-button{
    position: absolute;
    width: 50px;
    height: 50px;
    margin-bottom: -40px;
}