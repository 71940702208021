.condicion-reward{
    font-size: 200%
}

@media(max-width: 680px){
    .condicion-reward{
        font-size: 150%
    }
}

@media(max-width: 520px){
    .condicion-reward{
        font-size: 100%
    }
}