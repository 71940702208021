@import "./assets/scss/colors.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@font-face {
  font-family: "Chicken";
  src: local("Chicken"),
    url("./assets/fonts/ChickenScript_Regular.otf") format("truetype");
}
@font-face {
  font-family: "SansRegular";
  src: local("SansRegular"),
    url("./assets/fonts/ChickenSans-Regular.otf") format("truetype");
}
@font-face {
  font-family: "SansBold";
  src: local("SansBold"),
    url("./assets/fonts/ChickenSans-Bold.otf") format("truetype");
}

body {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  font-family: "SansRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cookie-animation-show{
  transform: translateY(0);
  transition-duration: 1s;
}
.cookie-animation-hide{
  transform: translateY(100%);
  transition: 1s;
}

.btn-primary{
  background-color: $burnt-orange;
}

code {
  font-family: "SansRegular"
}

.cookie-button{
  position: absolute;
  z-index: 0;
  bottom: 5px;
  left: auto;
  right: 5px;
  -webkit-border-radius: 8px !important;
  -khtml-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  border-radius: 8px !important;
}

.sans-bold{
  font-family: "SansBold"
}

.text-font-chicken{
  font-family: "Chicken";
}

.title-h1{
  font-weight: 600;
  font-size: 350%;
}

// Scrollbar design

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $butter-yellow;;
  border-radius: 50px;
}


// Modal rounded corners
.modal-content{
  -webkit-border-radius: 20px !important;
  -khtml-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important;
}

// Card rounded corners
.card-rounded{
  -webkit-border-radius: 10px !important;
  -khtml-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  border-radius: 10px !important;
}


// Carousel indicators

ol.carousel-indicators li {
  background-color: $red;
  border-radius: 50%;
  height: 15px;
  width: 15px;
}
ol.carousel-indicators li.active {
  background-color: $red;
}

@media (max-width: 800px) {
  ol.carousel-indicators li {
    height: 10px;
    width: 10px;
    margin-bottom: -12px;
  }
}

// Checkboxes

.round {
  position: relative;
}

.round label {
  background-color: $salt-white;
  border: 1px solid $border-gray;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.round label:after {
  border: 2px solid $salt-white;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: $blue;
  border-color: $blue;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.round input[type="checkbox-politicas"] {
  visibility: hidden;
}

.round input[type="checkbox-politicas"]:checked + label {
  background-color: $blue;
  border-color: $blue;
}

.round input[type="checkbox-politicas"]:checked + label:after {
  opacity: 1;
}

.round input[type="checkbox-condiciones"] {
  visibility: hidden;
}

.round input[type="checkbox-condiciones"]:checked + label {
  background-color: $blue;
  border-color: $blue;
}

.round input[type="checkbox-condiciones"]:checked + label:after {
  opacity: 1;
}

.navbar-toggler {
  font-size: 20px;
  padding: 0;
  margin-right: 5px;
}

@media (max-width: 600px){
  .title-h1{
      font-weight: 600;
      font-size: 250%;
  }
}

@media (max-width: 380px) {
  .navbar-toggler {
    font-size: 15px;
  }
}
