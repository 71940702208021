@import "../../assets/scss/colors.scss";

.download_app{
    height: auto;
    width: 100%;
}

.mobile{
    flex-direction: row;
}

.card-mobile{
    width: 100%;
    margin-top: 25px;
}

.burnt-orange{
    color: $burnt-orange;
}

.paragraf-width{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
}

.title-app{
    font-size: 70px;
}

.paragraf-text-size{
    font-size: 26px;
}

.rounded-number {
    width: 43px;
    height: 42px;
    border-radius: 50px;
    background-color: $blue;
    font-size: 30px;
}

.text-margin{
    margin-bottom: -3px;
}

.mobile_android_button img{
    background-size: contain;
    position: relative;
    width: 130px;
}

.mobile_ios_button img{
    background-size: contain;
    position: relative;
    width: 131px;
    margin-left: 20px;
    margin-right: 20px;
}


.mobile-buttons{
    justify-content: start;
    margin-left: 75px;
    margin-top: 50px;
}

@media (min-width: 1550px){
    .card-mobile{
        width: 100%;
        margin-top: 50px;
    }
    .title-app{
        font-size: 100px;
    }
    .paragraf-width{
        margin-top: 50px;
    }
    .paragraf-text-size{
        font-size: 30px;
    }
    .rounded-number {
        width: 50px;
        height: 49px;
        border-radius: 50px;
        background-color: $blue;
        font-size: 35px;
    }
    .mobile_android_button img{
        width: 149px;
    }
    
    .mobile_ios_button img{
        width: 150px;
        margin-left: 30px;
        margin-right: 30px;
    }
    .mobile-buttons{
        justify-content: start;
        margin-left: 100px;
        margin-top: 75px;
    }
}


@media (max-width: 1120px){
    .title-app{
        font-size: 50px;
    }
}

@media (max-width: 1100px){
    .mobile{
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .mobile-image{
        margin-left: -50px;
    }
    .padding{
        padding: 0;
        padding-bottom: 20px;
        align-items: center;
    }
    .mobile-buttons{
        justify-content: center;
        margin-left: 0px;
        margin-top: 50px;
        margin-bottom: 35px;
    }
    .paragraf-width{
        width: 90%;
    }
}
@media (max-width: 850px){
    .paragraf-width{
        width: 80%;
    }
}

@media (max-width: 650px){
    .paragraf-width{
        width: 100%;
    }
    .mobile_ios_button img{
        margin-bottom: 10px;
        margin-left: 0;
    }
}

@media (max-width: 500px){
    .title-app{
        font-size: 38px;
    }
    .paragraf-text-size{
        font-size: 16px;
    }
    .rounded-number {
        width: 40px;
        height: 39px;
        border-radius: 50px;
        background-color: $blue;
        font-size: 25px;
    }
    .paragraf-width{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .paragraf-width{
        width: 100%;
    }
    .mobile_ios_button img{
        margin-bottom: 10px;
        margin-left: 0;
    }
}

@media (max-width: 350px){
    
    .title-app{
        font-size: 33px;
    }
    
    .mobile-image{
        margin-left: -70px;
    }
    .paragraf-text-size{
        font-size: 15px;
    }
    .mobile_ios_button img{
        margin-bottom: 10px;
        margin-left: 0;
    }
}

@media (max-width: 308px){
    .title-app{
        font-size: 30px;
    }
    .mobile-buttons{
        flex-direction: column;
    }
    .paragraf-text-size{
        font-size: 13px;
    }
    .mobile_ios_button img{
        margin-bottom: 10px;
        margin-right: 0;
        margin-left: 0;
    }
}