
.title{
    font-family: "Chicken";
    height: 100px;
}

.close-size{
    width: 30px;
    height: 30px;
}

.contact-info-card{
    width: 50%;
}

.QR-img{
    width: 150px;
    height: 150px;
}

.love-size{
    margin-right: 15px;
    width: 230px;
    height: 200px;
}

.gracias{
    font-size: 20px;
}

.necesitamos{
    font-size: 20px;
}

.title-h1{
    font-weight: 600;
    font-size: 350%;
}

@media (max-width: 1050px){
    .contact-body{
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 800px){
    .contact-info-card{
        width: 100%;
    }
}