.map{
    height: 100vh;
    width: 100%;
}

.location-icons{
    width: 1.6rem;
    height: 2rem;
    margin-top: -2rem;
    margin-left: -1rem;
}

.location-icons-selected{
    width: 2.2rem;
    height: 2.5rem;
    margin-top: -2.5rem;
    margin-left: -1rem;
}