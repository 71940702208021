@import "../../assets/scss/colors.scss";

.title-recompensas{
    font-weight: 600;
    font-size: 350%;
}

.clipboard{
    height: 40px;
    width: 100%;
    -webkit-border-radius: 8px !important;
    -khtml-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    border-radius: 8px !important;
}

.card-background{
    background-color: $dirty-white;
}

.numbers{
    height: 75px;
    width: 75px;
}

.rewards-grayscale{
    width: 200px;
    height: 123px;
    filter: grayscale(100);
}

.rewards{
    width: 200px;
    height: 123px;
}

.star-filled{
    width: 100px;
    height: 100px;
}

.stars{
    width: 115px;
    height: 110px;
    margin: 6px;
}

.rewards-buttons{
    width: 200px;
    margin-left: 10px;
    margin-right: 10px;
}

.code{
    display: flex;
}

.parent {
    flex-wrap: wrap;
    width: 400px;
  }

.codigos{
    width: 90px;
    height: 60px;
}

.mis-codigos{
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
    overflow-y: scroll;
}

// Scrollbar mis recompensas

.mis-codigos::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .mis-codigos::-webkit-scrollbar-track {
    background: $salt-white;
  }
  
  /* Handle */
  .mis-codigos::-webkit-scrollbar-thumb {
    background: $butter-yellow;;
    border-radius: 50px;
  }


@media (max-width: 1200px){
    .text-size{
        font-size: 25px;
    }
}

@media (max-width: 1000px){
    .title-recompensas{
        font-weight: 600;
        font-size: 250%;
    }
    .title{
        height: 70px;
    }
    .two{
        flex-direction: column;
    }
    .one{
        flex-direction: column;
    }
    .obten-producto{
        text-align: center;
    }
}

@media (max-width: 650px){
    .parent{
        width: 250px;
    }
    .stars{
        height: 58px;
        width: 60px;
        margin-left: 10px;
    }

    .recompensas-buttons{
        flex-direction: column;
    }

    .rewards-buttons{
        width: 250px;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 15px;
    }

    .code{
        flex-direction: column;
    }

    .rewards-grayscale{
        width: 110px;
        height: 69px;
        filter: grayscale(100);
    }
    
    .rewards{
        width: 110px;
        height: 69px;
    }
}

@media (max-width: 420px){
    .parent{
        width: 200px;
    }
    .stars{
        height: 48px;
        width: 50px;
        margin-left: 5px;
    }

    .rewards-grayscale{
        width: 100px;
        height: 60px;
        filter: grayscale(100);
    }
    
    .rewards{
        width: 100px;
        height: 60px;
    }
}

@media (max-width: 350px){
    .parent{
        width: 160px;
    }
    .stars{
        height: 40px;
        width: 42px;
        margin-left: 5px;
    }

    .rewards-grayscale{
        width: 80px;
        height: 51px;
        filter: grayscale(100);
    }
    
    .rewards{
        width: 80px;
        height: 51px;
    }
}