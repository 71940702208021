@import "../../assets/scss/colors.scss";

.height{
}
.row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.column {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.button{
display: flex;
background-color: #FF7D00;
margin:30px;
  min-width: 270px;
  min-height: 120px;
justify-content: center;
align-items: center;
border-radius:8px;
}

.text{
  color:#FFF;
  font-weight: bold;
  font-size: 26px;
  margin:10px;
  min-width: 170px;
  text-align: center;
  vertical-align: center;
}
.extra-space{
}
.space{
margin:32px;
}

.alergens{
  background-color: $dirty-white;
  min-height: 90vh;
}
