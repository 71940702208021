
@import "../../assets/scss/colors.scss";

.tradicion{
    flex-direction: row;
}

.sabor{
    flex-direction: row-reverse;
}

.historia{
    height: auto;
}

.history-font-size{
    font-size: 53px;
}

.anos{
    margin-top: -10px;
}

.img-with-zigzag {
    position: relative;
    display: block;
    object-fit: cover;
    min-height: 500px;
}

.content-container {
    max-width: 1000px;
}

.video-overlay {
    max-width: 1500px;
    width: 90%;
    position: absolute;
    top: 26%;
    left: 50%;
    transform: translate(-50%, 0);
    min-height: 35%;
}

.last-video-margin {
    margin-bottom: -6px;
}

.stary-list {
    max-width: 50%;
}

.zigzag-top {
    transform: scaleY(-1);
    left: 0%;
    top: -2px;
    position: absolute;
}

.zigzag-bottom {
    left: 0%;
    bottom: 0%;
    position: absolute;
}

.chicken-font-title {
    font-weight: 300;
    font-size: 500%;
}

.circle-img {
    max-width: 25%;
}

.text-secondary-on-media {
    font-size: 180%;
    font-weight: 600;
    text-align: center;
    max-width: 35%;
    margin-right: -1%;
}

.star {
    height: 30px;
}

.align-items-normal{
    align-items:normal;
}
.chicken-wrapper {
    max-width: 49%;
}

.icon-on-media {
    max-width: 20%;
    margin-right: -14%;
    margin-left: 2%;
}

.years {
    color: $blue;
    font-size: 110%;
    font-weight: 600;
}

.primary-title {
    font-weight: 600;
    font-size: 350%;
}

@media only screen and (min-width: 1024px) and (max-width: 1507px) {
    .video-overlay {
        top: 15%;
    }
}

@media (max-width: 600px){
    .chicken-font-title {
        font-weight: 500;
        font-size: 260%;
        margin-top: 4px;
    }

    .primary-title {
        font-weight: 600;
        font-size: 200%;
    }

    .stary-list-title {
        align-items: center;
        justify-content: center;
        text-align: center;
    }
  }

@media (max-width: 1024px){
    .tradicion{
        flex-direction: column;
    }
    .sabor{
        flex-direction: column;
    }

    .zigzag-bottom {
        bottom: -1px;
    }

    .video-overlay {
        top: 10%;
        min-height: 80%;
        text-align: center;
    }

    .text-on-media-top-left {
        max-width: 100%;
        justify-content: center;
    }

    .text-on-media-top-left h1{
        font-size: 150%;
    }

    .text-on-media-bottom-right {
        position: relative;
        max-width: 100%;
        justify-content: center;
        text-align: center;
    }

    .text-on-media-middle h1{
        font-size: 200%;
    }

    .text-secondary-on-media {
        font-size: 110%;
        font-weight: 600;
        max-width: 95%;
        margin-right: 0%;
    }

    .stary-list {
        max-width: 100%;
    }

    .chicken-wrapper {
        max-width: 100%;
    }

    .icon-on-media {
        max-width: 33%;
        margin-right: 0%;
    }
}
