@import "../../assets/scss/colors.scss";


.success-bg-color {
    color: $salt-white;
    height: 50px;
    background-color: $green;
    box-shadow: 0 1px 5px rgb(0 0 0 / 1);
  }
  
.error-bg-color {
    color: $salt-white;
    height: 50px;
    background-color: $red;
    box-shadow: 0 1px 5px rgb(0 0 0 / 1);
  }
  