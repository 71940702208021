@import "../../assets/scss/colors.scss";


.login{
    background-color: $dirty-white;
    min-height: 90vh;
}

.login-card{
    margin-top: 50px;
    margin-bottom: 50px;
}

.space{
    margin-left: 30px;
    margin-right: 30px;
}

.register-button-login{
    bottom: 0;
}

.register-card {
    border-left: 1px solid $border-gray;
}

@media (max-width: 600px){
    .login-body{
        flex-direction: column;
    }
    .register-card {
        border-left: hidden;
    }
}



@media (max-width: 450px){
    .login-card{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .space{
        margin-left: 15px;
        margin-right: 15px;
    }
}