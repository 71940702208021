
// Colors Popeyes

$blue: #ff7d00;
$green: #00b2a9;
$teal: #39878F;
$red: #d90f06;
$black: #000000;
$cyan: #911987;
$burnt-orange: #f25600;
$butter-yellow: #ffc800;
$salt-white: #ffffff;
$dirty-white: #f8f6f6;
$border-gray: rgba(175, 175, 175, 0.486);
$history-color: #ecf0f2;
$calidad-color: #ecf0f245;