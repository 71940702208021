@import "../../assets/scss/colors.scss";
.cookie-body{
    height: 310px;
    z-index: 2;
}

.cookie-buttons{
    justify-content: space-between;
}

.margin-handle{
    margin-left: 5px;
    margin-right: 5px;
}

.cookie-table-head{
    background-color: $burnt-orange;
    color: white;
}

.cookie-table{
    font-size: 12px;
    table-layout: fixed;
    width: 100%;
}

.cookie-table-row{
    width: 30%;
    word-wrap: break-word;
}

.cookie-table-row-info{
    width: 100%;
}

@media(max-width: 950px){
    .cookie-body{
        height: 350px;
    }
}

@media(max-width: 760px){
    .mobile-size-text{
        font-size: 15px;
    }
}

@media(max-width: 650px){
    .cookie-body{
        height: 420px;
    }
    .mobile-size-text{
        font-size: 15px;
    }
}

@media(max-width: 550px){
    .cookie-buttons{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .cookie-body{
        height: 400px;
    }
    .mobile-size-text{
        font-size: 12px;
    }
}

@media(max-width: 420px){
    .cookie-body{
        height: 450px;
    }
    .margin-handle{
        margin-left: 0;
        margin-right: 0;
    }
    .cookie-buttons-column{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .mobile-size-text{
        font-size: 11px;
    }
}

@media(max-width: 380px){
    .cookie-body{
        height: 500px;
    }
    .cookie-buttons-column{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .mobile-size-text{
        font-size: 10px;
    }
}