
@import "../../assets/scss/colors.scss";

.forgot{
    height: 90vh;
}

// .forgot-password{
//     flex-direction: column;
// }

.success-forgot-password{
    height: 90vh;
}

.success-forgot-password-font{
    font-size: 20px;
}
.success-forgot-password-button{
    width: 250px;
    margin-left: 15px;
    margin-right: 15px;
}

.forgot-password-button{
    width: 250px;
    margin-left: 15px;
    margin-right: 15px;
}

.title-forgot{
    font-size: 50px;
}

.forgot-bg{
    background-color: $dirty-white;
}

@media (max-width: 600px){
    .success-forgot-password-buttons-group{
        flex-direction: column;
    }
    .success-forgot-password-button{
        width: 75%;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media(max-width: 450px){
    .title-forgot{
        font-size: 40px;
    }
}