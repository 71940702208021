@import "../../assets/scss/colors.scss";

.header-and-footer {
    background-color: $calidad-color;
}

.commitment-title {
    font-size: 53px;
}

.actions-text {
    font-size: 43px;
    color: $blue;
}

.group-image-left {
    flex-direction: row;
}

.group-image-right {
    flex-direction: row-reverse;
}

@media (max-width: 1000px){
    .group-image-left{
        flex-direction: column;
    }
    .group-image-right{
        flex-direction: column;
    }
}