
@import "../../assets/scss/colors.scss";

.calidad{
    height: auto;
}

.quality_background {
    background-color: $calidad-color;
}

.quality-font-size{
    font-size: 50px;
}

.origen{
    flex-direction: row;
}

.seguridad{
    flex-direction: row;
}

.elaboracion{
    flex-direction: row-reverse;
}


@media (max-width: 1000px){
    .origen {
        flex-direction: column;
    }
    .elaboracion{
        flex-direction: column;
    }
    .seguridad{
        flex-direction: column;
    }
}
