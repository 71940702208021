.category {
  width: 100%;
  min-height: 90vh;
}

.title {
  font-family: "Chicken";
  height: 100px;
}

.card-height-category {
  min-height: 135px;
  box-shadow: 0 0 10px 0px rgb(233, 227, 227);
}

.title-category {
  font-weight: 600;
  font-size: 350%;
}

.catalog-category-image {
  width: 100%;
  height: 160px;
  object-fit: contain;
}

.text-size {
    font-size: 18px;
  }

@media (max-width: 950px) {
  .text-size {
    font-size: 16px;
  }
  .catalog-category-image {
    width: 100%;
    height: 130px;
    object-fit: contain;
  }
}
@media (max-width: 500px) {
  .title-category {
    font-weight: 600;
    font-size: 250%;
  }
  .catalog-category-image {
    width: 100%;
    height: 100px;
    object-fit: contain;
  }
  
}
@media (max-width: 400px) {
  .text-size {
    font-size: 14px;
  }
  .title-category {
    font-weight: 600;
    font-size: 200%;
  }

  .catalog-category-image {
    width: 100%;
    height: 80px;
    object-fit: contain;
  }
}
@media (max-width: 340px) {
  .text-size {
    font-size: 13px;
  }
  .card-height-category {
    min-height: 120px;
    box-shadow: 0 0 10px 0px rgb(233, 227, 227);
  }
  .catalog-category-image {
    width: 100%;
    height: 70px;
    object-fit: contain;
  }
}
