
@import "../../assets/scss/colors.scss";

.not_found{
    width: 100%;
    height: auto;
}

.not_found_body{
    height: 90vh;
    background-color: $dirty-white;
}
