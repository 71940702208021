@import "../../assets/scss/colors.scss";

.footer {
    position: absolute;
    width: 100%;
    height: auto;
    background-color: $black;
}


.footer-height{
    min-height: 130px;
}

.btn.btn-rounded {
    width: 43px;
    height: 42px;
    border-radius: 50px
}
// Footer links

.footer_link {
    font-size: 15px;
    font-weight: 500;
    
}

.footer_up_link:hover,
.footer_up_link:focus{
    color: $blue;
}
.footer_up_link.active,
.footer_up_link:active{
    color: $blue;
}

.footer_up_link{
    color: $salt-white;
}

// Social icons

.social-icons li span{
    font-size: 24px;
    color: $salt-white;
    margin-left: -2px;
}


@media (max-width: 767px){
    .social-icons{
        justify-content: center;
    }
}